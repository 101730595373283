<template>
  <div class="lg:mt-3">
    <div class="text-2xl my-4 lg:hidden md:hidden title font-semibold">
      My Orders
    </div>
    <ClinicOrderList viewDetailRoute="OrderDetail"></ClinicOrderList>
  </div>
</template>

<script>
import ClinicOrderList from "../../../components/order-center/order/ClinicOrderList";
export default {
  name: "OrderList",
  components: { ClinicOrderList },
};
</script>

<style scoped>
.title {
  margin-top: -30px !important;
}
</style>
